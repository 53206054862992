import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import About from "../components/Repeating/About";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Industries & Applications | Fiberglass | Painted Rhino"
            description="With 25+ years of experience, a family-oriented approach, and superior customer service, Painted Rhino has established relationships within many industries."
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <section className="pt-8 md:pt-14 pb-20 md:pb-32">
            <div className="container">
               <div className="mb-12 md:mb-16">
                  <header className="md:text-center max-w-4xl mx-auto">
                     <h1>Industries & Applications</h1>
                     <p className="md:text-2xl mb-0">
                        With over 25 years of experience, a family-oriented approach, and dedication to superior customer service, Painted Rhino has
                        established relationships within a number of industries. Our capacities include but are not limited to:
                     </p>
                  </header>
               </div>

               <div className="grid grid-cols-1 md:grid-cols-3 gap-y-10 md:gap-x-10 md:gap-y-12">
                  <div className="group relative">
                     <div className="mb-4 overflow-hidden">
                        <Img
                           fadeIn={true}
                           loading="lazy"
                           className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                           fluid={data.massTransit.childImageSharp.fluid}
                           alt="Mass Transit"
                        />
                     </div>
                     <h3 className="text-lg mb-3">Mass Transit</h3>
                     <p className="mb-0">Get solid parts production for everything from driver dashes to steps and fender flares</p>
                     <AniLink fade to="/mass-transit-parts-manufacturer/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>

                  <div className="group relative">
                     <div className="mb-4 overflow-hidden">
                        <Img
                           fadeIn={true}
                           loading="lazy"
                           className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                           fluid={data.rvProducts.childImageSharp.fluid}
                           alt="RV Products"
                        />
                     </div>
                     <h3 className="text-lg mb-3">RV Products</h3>
                     <p className="mb-0">Premier manufacturing of RV components such as sinks, countertops, tanks, and pop-outs</p>
                     <AniLink fade to="/rv-component-manufacturer/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>

                  <div className="group relative">
                     <div className="mb-4 overflow-hidden">
                        <Img
                           fadeIn={true}
                           loading="lazy"
                           className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                           fluid={data.theming.childImageSharp.fluid}
                           alt="Theming"
                        />
                     </div>
                     <h3 className="text-lg mb-3">Theming</h3>
                     <p className="mb-0">Expert theme park design, custom signage and packaging, and props that make movie magic</p>
                     <AniLink fade to="/themed-parts-manufacturer/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>

                  <div className="group relative">
                     <div className="mb-4 overflow-hidden">
                        <Img
                           fadeIn={true}
                           loading="lazy"
                           className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                           fluid={data.industrialEquipment.childImageSharp.fluid}
                           alt="Industrial Equipment"
                        />
                     </div>
                     <h3 className="text-lg mb-3">Industrial Equipment</h3>
                     <p className="mb-0">We offer sturdy engine covers for lifts, man boxes for lift trucks, hoods for tractors</p>
                     <AniLink fade to="/fiberglass-product-manufacturer/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>

                  <div className="group relative">
                     <div className="mb-4 overflow-hidden">
                        <Img
                           fadeIn={true}
                           loading="lazy"
                           // className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                           fluid={data.infrastructure.childImageSharp.fluid}
                           alt="Infrastructure"
                        />
                     </div>
                     <h3 className="text-lg mb-3">Infrastructure</h3>
                     <p className="mb-0">Let us support you with utility covers, electrical trench covers, sump tanks</p>
                     {/* <AniLink fade to="">
                        <span className="link-overlay"></span>
                     </AniLink> */}
                  </div>

                  <div className="group relative">
                     <div className="mb-4 overflow-hidden">
                        <Img
                           fadeIn={true}
                           loading="lazy"
                           className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                           fluid={data.medical.childImageSharp.fluid}
                           alt="Medical"
                        />
                     </div>
                     <h3 className="text-lg mb-3">Medical</h3>
                     <p className="mb-0">Custom and high-quality cabinets or enclosures for medical equipment and devices</p>
                     <AniLink fade to="/fiberglass-medical-equipment-manufacturer/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>

                  <div className="group relative">
                     <div className="mb-4 overflow-hidden">
                        <Img
                           fadeIn={true}
                           loading="lazy"
                           className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                           fluid={data.marine.childImageSharp.fluid}
                           alt="Marine"
                        />
                     </div>
                     <h3 className="text-lg mb-3">Marine</h3>
                     <p className="mb-0">Hit the deck with boats, bait tanks, dock boxes, pylon tops, and other components</p>
                     <AniLink fade to="/marine-fiberglass-manufacturer/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>

                  <div className="group relative">
                     <div className="mb-4 overflow-hidden">
                        <Img
                           fadeIn={true}
                           loading="lazy"
                           // className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                           fluid={data.signage.childImageSharp.fluid}
                           alt="Signage"
                        />
                     </div>
                     <h3 className="text-lg mb-3">Signage</h3>
                     <p className="mb-0">Stand out with custom signage, brand and design elements, and other displays</p>
                     {/* <AniLink fade to="">
                        <span className="link-overlay"></span>
                     </AniLink> */}
                  </div>

                  <div className="group relative">
                     <div className="mb-4 overflow-hidden">
                        <Img
                           fadeIn={true}
                           loading="lazy"
                           className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                           fluid={data.fiberglassShelters.childImageSharp.fluid}
                           alt="Fiberglass Shelters"
                        />
                     </div>
                     <h3 className="text-lg mb-3">Fiberglass Shelters</h3>
                     <p className="mb-0">Easy-to-assemble (and disassemble) prefabricated fiberglass pop-up shelters</p>
                     <AniLink fade to="/fiberglass-shelter-manufacturer/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>

                  <div className="group relative">
                     <div className="mb-4 overflow-hidden">
                        <Img
                           fadeIn={true}
                           loading="lazy"
                           className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                           fluid={data.fiberglassDecor.childImageSharp.fluid}
                           alt="Fiberglass Shelters"
                        />
                     </div>
                     <h3 className="text-lg mb-3">Fiberglass Decor</h3>
                     <p className="mb-0">From furniture to sculptures, fine art, and more, the possibilities are endless.</p>
                     <AniLink fade to="/fiberglass-decor-manufacturer/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
               </div>

               <div className="bg-gray-50 px-6 py-12 md:py-16 mt-12 md:mt-14">
                  <header className="text-center">
                     <h3>Other Applications</h3>
                     <p>You dream, we build—facades, modesties, cell towers, unsightly blend in covers, and more</p>
                     <ButtonSolid as="button" data="modal-contact" text="Get a Quote" />
                  </header>
               </div>
            </div>
         </section>

         <TestimonialBackgroundImage />
         <About className="pt-20 md:pt-32 mb-20 md:mb-28" />
         <CTABackgroundImage />
      </Layout>
   );
};

export default Page;

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Industries_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Industries_Twitter.jpg" }) {
         publicURL
      }
      fiberglassShelters: file(relativePath: { eq: "industries/Fiberglass Shelters.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      fiberglassDecor: file(relativePath: { eq: "industries/decor/Fiberglass Decor Hub Image.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      industrialEquipment: file(relativePath: { eq: "industries/Industrial Equipment.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      infrastructure: file(relativePath: { eq: "industries/Infrastructure.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      marine: file(relativePath: { eq: "industries/Marine.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      massTransit: file(relativePath: { eq: "industries/Mass Transit.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      medical: file(relativePath: { eq: "industries/Medical.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      rvProducts: file(relativePath: { eq: "industries/RV Products.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      signage: file(relativePath: { eq: "industries/Signage.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      theming: file(relativePath: { eq: "industries/Theming.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 746) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;
